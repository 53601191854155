import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./image/logo-gleton.png";

const App = () => {
  return (
    <div>
      <Container>
        <Row className="d-flex text-center align-items-center vh-100">
          <Col className="mb-5 pb-5">
            <img className="img-fluid mb-4" src={logo} alt="" />
            <div className="text-muted">
              <h5>Gleton s.r.o.</h5>
              <div>IČ: 01930257</div>
              <div>DIČ: CZ01930257</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App;
